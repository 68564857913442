import type { UseFetchOptions } from '#app'
import { defu } from 'defu'

import { TTokenCookieName } from '@tms/authorization'

const defaultHeadersConfig = () => {
  const date = new Date()
  const offset = `${date.getTimezoneOffset() / -60}`
  return {
    _off_set: offset
  }
}

const sessionHeader = () => {
  const accessToken = useCookie(TTokenCookieName.accessToken)
  return {
    _tma_token: accessToken.value ?? '',
    _tmr_token: useCookie(TTokenCookieName.refreshToken).value ?? ''
  }
}

export function useCustomFetch<T> (url: string, options: UseFetchOptions<T> = {}) {
  const config = useRuntimeConfig()
  const AUTH_BASE_URL: string = config.public.API_HOST

  const defaults: UseFetchOptions<T> = {
    baseURL: AUTH_BASE_URL,
    headers: {
      ...options.headers,
      ...defaultHeadersConfig(),
      ...sessionHeader()
    },
    onResponse (_ctx) {
      const response = _ctx.response._data
      if (response.code !== 0) {
        throw createError({ statusCode: response.code, statusMessage: response.message, fatal: true })
      }
      _ctx.response._data = response.data
    },
    onResponseError (_ctx) {
      // console.log('onResponseError > ', _ctx.error)
    }
  }

  const params = defu(options, defaults)
  return useFetch(url, params)
}
